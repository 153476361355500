import React from 'react'
import '../css/Loginİmage.css'
function Loginİmage() {
  return (
    <>

    <div class="container">
        <h1 className='imageText'>
            Welcome to EscootersCZ
        </h1>
        <button></button>
        <img className='Mainİmage' src='https://res.cloudinary.com/sharp-consumer-eu/image/fetch/w_3100,f_auto,q_auto/https://s3.infra.brandquad.io/accounts-media/SHRP/DAM/origin/b51ac6ba-b57e-11ec-a1c4-a6058a2d8b66.jpg' alt="" />
    </div>
    </>
  );
};


export default Loginİmage;