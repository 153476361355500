import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './vars/reportWebVitals';
import App from './vars/App'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <App />
    </BrowserRouter>
);
reportWebVitals();
