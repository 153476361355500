import { Link } from "react-router-dom"

export const DocsElements = () => {
    const containerStyle = {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
      };
      
      const buttonStyle = {
        textDecoration: 'none',
        cursor: 'pointer',
        border: '1px solid black',
        padding: '8px 16px',
        display: 'inline-block',
        marginBottom: '20px',
      };
      
      const contentStyle = {
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '8px',
      };
      
      const paragraphStyle = {
        marginBottom: '20px',
      };
    return (
        <div style={containerStyle}>
      <Link to={'/'} style={buttonStyle}>
        <p style={{ color: 'black' }}>Back</p>
      </Link>
      <div style={contentStyle}>
        <h3 id="customer-support" >Customer Support</h3>
        <p style={paragraphStyle}>
        Customer Support

Welcome to escooterscz.com Customer Support! We're here to assist you with any questions, concerns, or inquiries you may have regarding our products and services. Our dedicated team is committed to providing you with the best possible assistance to ensure your satisfaction.

Contact Information:

Email: info@consulstres.com
Operating Hours:

Monday to Friday: 9:00 AM - 5:00 PM (Local Time)
Saturday and Sunday: Closed

Services Offered:

Product Information: Get detailed information about our scooters, including specifications, features, and pricing.

Order Assistance: Track your order, modify or cancel an order, or resolve any issues related to your purchase.

Technical Support: Receive assistance with setting up your scooter, troubleshooting technical issues, or understanding product functionalities.

Returns and Refunds: Initiate returns, request refunds, or inquire about our return policy.
General Inquiries: Any other questions or concerns you may have about our company, policies, or services.

How to Reach Us:

Email: Send us an email at info@consulstres.com, and one of our representatives will get back to you promptly.

At escooterscz.com, your satisfaction is our top priority. We strive to provide timely and effective support to ensure a seamless shopping experience for our valued customers. Don't hesitate to reach out to us with any questions or concerns you may have – we're here to help!

        </p>
        <h3 id="privacy-policy">Privacy Policy</h3>
        <p style={paragraphStyle}>
        Privacy Policy

Last updated: [12.03.2024]

This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.

We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.

Information Collection and Use

While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include but is not limited to your name ("Personal Information").

Log Data

Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.

Communications

We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that relates to our business.

Cookies

Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.

Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.

Security

The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.

Changes to This Privacy Policy

This Privacy Policy is effective as of [Date] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.

We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.

Contact Us

If you have any questions about this Privacy Policy, please contact us.
        </p>
        <h3 id="terms-of-service">Terms Of Service</h3>
        <p style={paragraphStyle}>
        These Terms of Service ("Terms") govern your use of the escooterscz.com website and the services offered through the Site. By accessing or using the Site, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access the Site or use our services.

1. Use of the Site

1.1 You must be at least 18 years old to use the Site and its services.

1.2 You agree to provide accurate, current, and complete information when using the Site and its services.

1.3 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

1.4 You agree not to use the Site for any unlawful or unauthorized purpose, including but not limited to violating any applicable laws or regulations.

2. Products and Orders

2.1 The products displayed on the Site are subject to availability and may be discontinued or modified at any time without notice.

2.2 By placing an order through the Site, you agree to provide accurate and complete information about yourself, including your shipping address and payment details.

2.3 All orders are subject to acceptance and confirmation by escooterscz.com. We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, pricing errors, or suspected fraudulent activity.

3. Payment

3.1 Payment for orders placed through the Site must be made using the payment methods accepted by escooterscz.com.

3.2 All prices listed on the Site are in the currency specified and are exclusive of applicable taxes and shipping charges, unless otherwise indicated.

3.3 By providing your payment information, you authorize escooterscz.com to process and charge the total amount for your order, including any applicable taxes and shipping fees, to your chosen payment method.

4. Intellectual Property

4.1 All content, trademarks, logos, and intellectual property displayed on the Site are owned or licensed by escooterscz.com and are protected by copyright and other intellectual property laws.

4.2 You may not use, reproduce, distribute, or modify any content from the Site without prior written consent from escooterscz.com.

5. Limitation of Liability

5.1 To the fullest extent permitted by law, escooterscz.com shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way related to your use of the Site or its services.

6. Governing Law

6.1 These Terms of Service shall be governed by and construed in accordance with the laws of Estonia, without regard to its conflict of law provisions.

7. Changes to Terms

7.1 escooterscz.com reserves the right to modify or update these Terms of Service at any time without prior notice. The revised Terms will be effective immediately upon posting on the Site. Your continued use of the Site after the posting of any changes constitutes acceptance of those changes.

If you have any questions or concerns about these Terms of Service, please contact us at  info@consulstres.com


        </p>
      </div>
    </div>
    )
}