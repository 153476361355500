import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

const PayPalPayment = ({ clientId, amount }) => {
  
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount.toFixed(2), // Convert the integer amount to dollars with two decimal places
            currency_code: 'USD', // Convert amount to dollars
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      alert('Transaction completed by ' + details.payer.name.given_name);
    });
  };

  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      options={{
        clientId: clientId, // Pass your PayPal client ID here
      }}
    />
  );
};

export default PayPalPayment;